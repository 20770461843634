/* ---- reset ---- */

body {
    margin: 0;
    font: normal 75% Arial, Helvetica, sans-serif;
  }
  
  canvas {
    display: block;
    vertical-align: bottom;
    width: fit-content;
    height: fit-content;
  }
  
  /* ---- particles.js container ---- */
  
  #tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    /* z-index: 0; */
  }

  .App-header {
    /* min-height: 100vh;
    position: absolute;
    left: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* height: 100px;
    width: 100px; */
    grid-area: inner-div;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
    z-index: 1;
  }

  .App {
    /* text-align: center; */
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100vh;
    grid-template-areas: "inner-div";
  }

  .App-link {
    color: #00a4f0;
  }
